<template>
  <div class="home">
    <app-header />
    <page-intro />
    <advantages-block />
    <solutions-block />
    <who-we-help />
    <industries-block />
    <company-block />
    <app-footer />
    <contact-modal />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    PageIntro: () => import("@/components/blocks/PageIntro.vue"),
    AdvantagesBlock: () => import("@/components/blocks/AdvantagesBlock.vue"),
    SolutionsBlock: () => import("@/components/blocks/SolutionsBlock.vue"),
    WhoWeHelp: () => import("@/components/blocks/WhoWeHelp.vue"),
    IndustriesBlock: () => import("@/components/blocks/IndustriesBlock.vue"),
    CompanyBlock: () => import("@/components/blocks/CompanyBlock.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    ContactModal: () => import("@/components/modals/ContactModal.vue"),
  },
};
</script>
